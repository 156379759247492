import XHRWrap from "./xhrWrap";
import Simplebar from "simplebar";
import DuplicateController from "./duplicateController";
class FileUploader {
    constructor(settings = {}) {
        this.elements = {
            main: typeof(settings.main) === "undefined" ? document.querySelector("[data-file-uploader='main']") : settings.main,
            mainWrap: null,
            filesList: null,
            foldersList: null,
            uploadList: null,
            pagination: null,
            topMenu: null,
            finder: {
                wrap: null,
                toggle: null,
                inputName: null,
                inputArticle: null,
                inputId: null,
                submit: null,
            },
            fileAdd: {
                wrap: null,
                id: null,
                width: null,
                height: null,
                path: null,
                thumbnail: null,
                categoryTitle: null,
                name: null,
                preview: null,
            },
            upload: {
                input: null,
                count: null,
                countMax: null,
                list: null,
                progress: {
                    name: null,
                    bar: null,
                    counter: null,
                },
            },
            settings: {
                wrap: null,
                btn: null,
                options: null,
            },
            listLimit: null,
            form: null,
        };
        this.urls = {
            folders: "/admin/api/v1/categories-tree",
            upload: "/admin/api/v1/image-upload",
            files: "/admin/api/v1/images",
            resize: "/admin/api/v1/resizeimage",
            addFolder: "/admin/api/v1/category-add",
        };
        this.xhrs = {
            loadImages: null,
            loadFolders: null,
            getResizedURL: null,
            addFolder: null,
        };
        this.fileToAdd = {
            id: null,
            width: null,
            height: null,
            path: null,
            thumbnail: null,
            categorytitle: null,
            name: null,
            alt: null,
            source: null,
            description: null,
            showDefault: null,
            save: null,
        }
        this.duplicateController = null;
        this.sortable = {
            gallery: typeof(settings.sortable) === "undefined" && typeof(settings.sortable.gallery) === "undefined" ? null : settings.sortable.gallery,
            lead: typeof(settings.sortable) === "undefined" && typeof(settings.sortable.lead) === "undefined" ? null : settings.sortable.lead,
            files: typeof(settings.sortable) === "undefined" && typeof(settings.sortable.files) === "undefined" ? null : settings.sortable.files,
        }
        this.state = {
            loading: true,
            promises: [],
            page: 1,
            pages: null,
            listLimit: 20,
            filesCategory: 3,
            foldersCategory: 5,
            buttonSelector: settings.buttonSelector != null ? settings.buttonSelector : null,
            folderCategory: "",
            contentType: typeof(settings.contentType) === "undefined" ? "image" : settings.contentType,
            duplicateSelector: typeof(settings.duplicateSelector) === "undefined" ? "input[name*='[image]']" : settings.duplicateSelector,
            finder: {
                name: "",
                article: "",
                id: ""
            }
        }
        this.init();
    }
    createConnection(list) {
        const self = this;
        // Try to find the counter of the list or use the length of the list
        const countImages = document.querySelector("[data-count-images]");
        var counter = parseInt(countImages.dataset.countImages);
        var counterWidget = parseInt(list.dataset.widgetCounter);
        console.log(list, counterWidget);
        // grab the prototype template
        var newWidget = list.dataset.prototype;
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        if (self.state.contentType == "image") {
            newWidget = newWidget.replace(/__name__/g, counter);
        }
        else {
            newWidget = newWidget.replace(/__name__/g, counterWidget);
        }
        // Increase the counter
        // And store it, the length cannot be used if deleting widgets is allowed
        counter++;
        counterWidget++;
        list.dataset.widgetCounter = counterWidget;
        countImages.dataset.countImages = counter;
        // create a new list element and add it to the list
        var newElem = self.stringToHTML(list.dataset.widgetTags);
        newElem.innerHTML = newWidget;
        list.appendChild(newElem);
        return newElem;
    }
    stringToHTML(str) {
        if (!window.DOMParser) return false;
    	var parser = new DOMParser();
        var support = false;
        try {
    		parser.parseFromString('x', 'text/html');
            support = true;
        }
        catch(err) {
    		support = false;
    	}
        if (support) {
            var doc = parser.parseFromString(str, 'text/html');
            return doc.body.children[0];
        }
    }
    addUploadEvent() {
        const self = this;
        if (self.elements.upload.input) {
            self.elements.upload.input.addEventListener("change", function(e) {
        		var filesArr = [];
        		var form = self.elements.form;
        		var files = self.elements.upload.input.files;
        		var uploadList = self.elements.upload.list;
        		var uploadName = self.elements.upload.progress.name;

        		var progressBar = self.elements.upload.progress.bar;
        		var progressCounter = self.elements.upload.progress.counter;
        		var listCount = self.elements.upload.count;
        		var listCountMax = self.elements.upload.countMax;
        		uploadList.innerHTML = "";
        		uploadName.textContent = "";
        		for (var i = 0; i < files.length; i++) {
        			filesArr.push(files[i]);
        			var listElement = document.createElement("li");
        			listElement.classList.add("file-uploader__upload-list-item");
        			listElement.classList.add("icon-file");
        			listElement.textContent = files[i].name;
        			uploadList.appendChild(listElement);
        		}
        		var uploadElements = self.elements.upload.list.children;
        		listCount.textContent = 0;
        		listCountMax.textContent = files.length;
                self.elements.main.dataset.upload = "true";
        		filesArr.reduce( (p, _, i) =>
        		    p.then(_ => new Promise(resolve =>
        		        setTimeout(function () {
        					uploadElements[i].classList.add("upload");
        					uploadElements[i].classList.remove("icon-file");
        					uploadElements[i].classList.add("icon-upload");

        					progressBar.style.right = "100%";
        					uploadName.textContent = filesArr[i].name;

        					var newData = new FormData();
        					newData.delete("file");
        					// newData.delete("categories_id");
        					newData.set("file", filesArr[i]);
        					// newData.set("categories_id", categoriesId);

        					var xhttp = new XMLHttpRequest();
        					xhttp.upload.onprogress = function(e){
        						var pc = parseInt(100 - (e.loaded / e.total * 100));
        						var percent = parseInt(e.loaded / e.total * 100);
        						progressBar.style.right = pc + "%";
        						progressCounter.textContent = percent + "%";
        					};
                            let uElement = uploadElements[i];
        					xhttp.onload = function() {
                                if (xhttp.status == 201) {
                                    var resArr = JSON.parse(xhttp.response);
                                    if (resArr.success == false || resArr == false) {
                                        uElement.classList.add("icon-cancel");
                                        uElement.classList.remove("icon-upload");
                                        uElement.classList.add("response");
                                        uElement.innerHTML += "<div class='file-loader__list-item--message'>" + resArr.message + "</div>";
                                        console.error("Abort", xhttp.responseText);
                                    }
                                    else {
                                        progressBar.style.right = "100%";
                                        listCount.textContent = parseInt(listCount.textContent) + 1;
                                    }
                                }
                                else {
                                    console.error(xhttp);
                                }
        					};
        					xhttp.onloadend = function() {
        						uploadElements[i].classList.remove("icon-upload");
                                if (xhttp.status != 413) {

                                    uploadElements[i].classList.add("icon-save");
                                    uploadElements[i].classList.add("response");
                                }
                                else {
                                    uploadElements[i].classList.add("icon-cancel");
            						uploadElements[i].classList.add("response");
                                }
        						resolve();
        						if (i == filesArr.length - 1) {
                                    self.elements.main.dataset.upload = "uploaded";
        						}
        					};
        					xhttp.onabort = function () {
        						uploadElements[i].classList.add("icon-cancel");
        						uploadElements[i].classList.add("response");
        						console.error("Abort", xhttp.responseText);
        					};
        					xhttp.open("POST", self.urls.upload + "?category=" + self.state.folderCategory, true);
        				    xhttp.send(newData);
        		        }, 1)
        		    ))
        		, Promise.resolve() );
        	});
        }
    }
    setElementsOrder(list, selector = "[name*='[ordering]']") {
        const elements = list.children;
        for (var i = 0; i < elements.length; i++) {
            let input = elements[i].querySelector(selector);
            input.value = i + 1;
        }
    }
    setSortable() {
        const self = this;
        if (self.sortable.gallery) {
            const gallerySort = new Sortable(self.sortable.gallery, {
                swapThreshold: 1,
                animation: 150,
                sort: true,
                handle: '.drag-wrapper',
                onSort: function () {
                    self.setElementsOrder(self.sortable.gallery);
                }
            });
        }
        if (self.sortable.lead) {
            const leadSort = new Sortable(self.sortable.lead, {
                swapThreshold: 1,
                animation: 150,
                sort: true,
                handle: '.drag-wrapper',
                onSort: function () {
                    self.setElementsOrder(self.sortable.lead);
                }
            });
        }
        if (self.sortable.files) {
            const filesSort = new Sortable(self.sortable.files, {
                swapThreshold: 1,
                animation: 150,
                sort: true,
                handle: '.drag-wrapper',
                onSort: function () {
                    self.setElementsOrder(self.sortable.files, "[name*='[order]']");
                }
            })
        }
    }
    getElements() {
        if (this.elements.main) {
            this.elements.mainWrap = this.elements.main.querySelector("[data-file-uploader='main-wrap']");
            this.elements.topMenu = this.elements.main.querySelector("[data-file-uploader='top-menu']");
            this.elements.filesList = this.elements.main.querySelector("[data-file-uploader='files-list']");
            this.elements.foldersList = this.elements.main.querySelector("[data-file-uploader='folders-list']");
            this.elements.uploadList = this.elements.main.querySelector("[data-file-uploader='upload-list']");
            this.elements.pagination = this.elements.main.querySelector("[data-file-uploader='pagination']");
            this.elements.paginationList = this.elements.main.querySelector("[data-file-uploader='pagination-list']");
            this.elements.listLimit = this.elements.main.querySelector("[data-file-uploader='list-limit']");
            this.elements.form = this.elements.main.closest("form");
            this.elements.addedFiles = document.querySelectorAll("ul[data-list-type]");
            this.elements.finder.wrap = this.elements.main.querySelector("[data-file-uploader='finder-wrap']");
            if (this.elements.finder.wrap) {
                this.elements.finder.toggle = this.elements.finder.wrap.querySelector("[data-file-uploader='finder-toggle']");
                this.elements.finder.inputName = this.elements.finder.wrap.querySelector("input[name='finder-name']");
                this.elements.finder.inputArticle = this.elements.finder.wrap.querySelector("input[name='finder-article']");
                this.elements.finder.inputId = this.elements.finder.wrap.querySelector("input[name='finder-id']");
                this.elements.finder.submit = this.elements.finder.wrap.querySelector("[data-file-uploader='finder-submit']")
            }
            this.elements.fileAdd.wrap = this.elements.main.querySelector("[data-file-uploader='add-file']");
            if (this.elements.fileAdd.wrap) {
                this.elements.fileAdd.id = this.elements.main.querySelector("[data-file-uploader='add-file-id']");
                this.elements.fileAdd.width= this.elements.main.querySelector("[data-file-uploader='add-file-width']");
                this.elements.fileAdd.height = this.elements.main.querySelector("[data-file-uploader='add-file-height']")
                this.elements.fileAdd.alt = this.elements.main.querySelector("[data-file-uploader='add-file-alt']");
                this.elements.fileAdd.description = this.elements.main.querySelector("[data-file-uploader='add-file-description']");
                this.elements.fileAdd.source = this.elements.main.querySelector("[data-file-uploader='add-file-source']");
                this.elements.fileAdd.popup = this.elements.main.querySelector("[data-file-uploader='add-file-popup']");
                this.elements.fileAdd.path = this.elements.main.querySelector("[data-file-uploader='add-file-path']");
                this.elements.fileAdd.thumbnail = this.elements.main.querySelector("[data-file-uploader='add-file-thumbnail']");
                this.elements.fileAdd.categoryTitle = this.elements.main.querySelector("[data-file-uploader='add-file-categoryTitle']");
                this.elements.fileAdd.name = this.elements.main.querySelector("[data-file-uploader='add-file-name']");
                this.elements.fileAdd.preview = this.elements.main.querySelector("[data-file-uploader='add-file-preview']");
                this.elements.fileAdd.showDefault = this.elements.main.querySelector("[data-file-uploader='add-file-default']");
                this.elements.fileAdd.save = this.elements.main.querySelector("[data-file-uploader='add-file-save']");
            }
            this.elements.upload.input = this.elements.main.querySelector("[data-file-uploader='upload-input']");
            if (this.elements.upload.input) {
                this.elements.upload.list = this.elements.main.querySelector("[data-file-uploader='upload-list']");
                this.elements.upload.count = this.elements.main.querySelector("[data-file-uploader='upload-count']");
                this.elements.upload.countMax = this.elements.main.querySelector("[data-file-uploader='upload-count-max']");
                this.elements.upload.progress.name = this.elements.main.querySelector("[data-file-uploader='upload-progress-file']");
                this.elements.upload.progress.bar = this.elements.main.querySelector("[data-file-uploader='upload-progress-bar']");
                this.elements.upload.progress.counter = this.elements.main.querySelector("[data-file-uploader='upload-progress-counter']");
            }
            this.elements.settings.wrap = this.elements.main.querySelector("[data-file-uploader='settings']");
            if (this.elements.settings.wrap) {
                this.elements.settings.options = this.elements.main.querySelector("[data-file-uploader='settings-options']");
                this.elements.settings.btn = this.elements.main.querySelector("[data-file-uploader='settings-btn']");
            }
        }
    }
    addMenuEvent() {
        const self = this;
        if (this.elements.topMenu) {
            this.elements.topMenu.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("mode")) {
                    self.state.mode = e.target.dataset.mode;
                    self.elements.main.dataset.mode = e.target.dataset.mode;
                    if (e.target.dataset.mode == "list") {
                        self.elements.main.dataset.loading = "true";
                        self.loadFolders();
                        self.loadImages();
                        Promise.all(self.state.promises).then(
                            result => {
                                self.state.loading = false;
                                self.elements.main.dataset.loading = "false";
                            }
                        )
                    }
                }
            });
        }
    }
    addCloseEvents() {
        const self = this;
        self.elements.main.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("fileUploader")) {
                if (e.target.dataset.fileUploader == "close") {
                    self.elements.main.dataset.open = "false";
                }
            }
            if (e.target.dataset.hasOwnProperty("fileUploader")) {
                if (e.target.dataset.fileUploader == "close-add") {
                    self.elements.fileAdd.wrap.dataset.open = false;
                }
            }
        });
    }
    runDuplicateController() {
        if (this.elements.addedFiles.length > 0) {
            this.duplicateController = new DuplicateController(this.elements.addedFiles, this.state.duplicateSelector);
        }
    }
    getData() {
        if (this.elements.main) {
            this.state.mode = this.elements.main.dataset.mode;
        }
        if (this.elements.listLimit) {
            this.state.listLimit = this.elements.listLimit.value;
        }
        if (this.elements.finder.wrap) {
            this.state.finder.name = this.elements.finder.inputName.value;
            this.state.finder.article = this.elements.finder.inputArticle.value;
            this.state.finder.id = this.elements.finder.inputId.value;
        }
    }
    setCustomScroll() {
        if (this.elements.filesList) {
            const imagesScroll = new Simplebar(this.elements.filesList.parentElement, {
                autoHide: false,
            })
        }
    }
    loadImages(page = this.state.page, url = this.urls.files, data = {
        limit: this.state.listLimit,
        page: this.state.page,
        name: this.state.finder.name,
        contentTitle: this.state.finder.article,
        contentId: this.state.finder.id,
        category: this.state.folderCategory,
    }) {
        const self = this;
        this.xhrs.loadImages = new XHRWrap({
            url: url,
            type: "GET",
            data: new URLSearchParams(data),
        });
        if (this.xhrs.loadImages.data.toString().length > 0) {
            this.xhrs.loadImages.url += "?" + this.xhrs.loadImages.data.toString();
        }
        this.state.promises.push(this.xhrs.loadImages.send());
        this.xhrs.loadImages.send().then(
            result => {
                if (result.page) {
                    self.state.page = result.page;
                }
                if (result.pages) {
                    self.state.pages = result.pages;
                }
                self.createPagination(result, self.elements.paginationList);
                if (self.elements.filesList) {
                    self.elements.filesList.innerHTML = result.html;
                    let elements = self.elements.main.querySelectorAll("[data-file-uploader='file']");
                    self.duplicateController.setUsedImages(elements, self.state.filesCategory);
                }
            },
            error => {
                console.log(error);
            }
        )
    }
    loadFolders(category = this.state.foldersCategory) {
        const self = this;
        this.xhrs.loadFolders = new XHRWrap({
            url: self.urls.folders + "?type=" + category,
            type: "GET"
        });
        this.state.promises.push(this.xhrs.loadFolders.send());
        this.xhrs.loadFolders.send().then(
            result => {
                if (self.elements.foldersList) {
                    self.elements.foldersList.innerHTML = result.html;
                    const elements = self.elements.foldersList.querySelectorAll("[data-category-id]");
                    for (var i = 0; i < elements.length; i++) {
                        elements[i].parentElement.classList.remove("active");
                        if (elements[i].dataset.categoryId == self.state.folderCategory) {
                            elements[i].parentElement.classList.add("active");
                        }
                    }
                }
            },
            error => {
                console.log(error);
            }
        )
    }
    addFoldersListEvent() {
        const self = this;
        if (self.elements.foldersList) {
            self.elements.foldersList.addEventListener("click", function(e){
                if (event.target.classList.contains("js-add-cat")) {
                    event.preventDefault();
                    event.stopPropagation();
                    var newId = event.target.dataset.id;
                    var newList = document.createElement("ul");
                    newList.classList.add("file-uploader__add-folder-ul");
                    var newListEl = document.createElement("li");
                    var newLink = document.createElement("a");
                    var folderForm = document.createElement("form");
                    folderForm.classList.add("file-uploader__add-folder");
                    var folderSend = document.createElement("button");
                    folderSend.classList.add("file-uploader__add-folder-submit");
                    var folderName = document.createElement("input");
                    folderName.classList.add("file-uploader__add-folder-name");
                    folderName.type = "text";
                    folderName.dataset.close = "true";
                    folderSend.type = "submit";
                    folderSend.dataset.close = "true";
                    folderSend.innerText = "+";
                    folderName.placeholder = "Nazwa folderu";
                    newList.appendChild(newListEl);
                    folderForm.appendChild(folderName);
                    folderForm.appendChild(folderSend);
                    newListEl.appendChild(folderForm);
                    event.target.parentElement.parentElement.appendChild(newList);
                    folderName.focus();
                    document.addEventListener("click", function(event){
                        if (event.target.dataset.close != "true") {
                            newList.remove();
                        }
                    });
                    folderForm.addEventListener("submit", function(event){
                        event.preventDefault();
                        self.elements.main.dataset.loading = "true";
                        var catName = folderName.value;
                        var link = self.urls.addFolder+ "?name=" + catName + "&type=5&parent=" + newId;

                        self.xhrs.addFolder = new XHRWrap({
                            url: link,
                            type: "POST"
                        });
                        self.xhrs.addFolder.send().then(
                            result => {
                                self.elements.main.dataset.loading = "true";
                                self.loadImages();
                                self.loadFolders();
                                Promise.all(self.state.promises).then(
                                    result => {
                                        self.elements.main.dataset.loading = "false";
                                    },
                                    error => {
                                        console.log(error);
                                    }
                                )
                            },
                            error => {
                                console.error(error);
                                alert(error);
                            }
                        );

                    });
                }
                if (e.target.dataset.hasOwnProperty("categoryId")) {
                    self.elements.main.dataset.loading = "true";
                    self.state.folderCategory = e.target.dataset.categoryId;
                    self.loadImages();
                    self.loadFolders();
                    Promise.all(self.state.promises).then(
                        result => {
                            self.elements.main.dataset.loading = "false";
                        },
                        error => {
                            console.log(error);
                        }
                    )
                }
            });
        }
    }
    createPagination(result, element, limit = 9) {
        element.innerHTML = "";
        let checkLimit = limit;
        if (parseInt(limit / 2) * 2 == checkLimit ) {
            limit -= 1;
        }
        let page = result.page - 1;
        if (page < 0) {
            page = 0;
        }
        let offset = (limit - 1) / 2;
        let pages = result.pages;
        let pagesArr = Array.from(Array(pages).keys());
        let startIndex = page - offset;
        let endIndex = page + offset;
        if (startIndex < 0) {
            endIndex += Math.abs(startIndex);
            startIndex = 0;
        }
        if (endIndex > pagesArr.length - 1) {
            if (pages > limit) {
                startIndex -= endIndex - (pagesArr.length - 1);
                if (startIndex < 0) {
                    startIndex = 0;
                }
            }
            endIndex = pagesArr.length - 1;
        }
        let paginationArr = pagesArr.slice(startIndex, endIndex + 1);
        for (var i = 0; i < paginationArr.length; i++) {
            let paginationItem = document.createElement("div");
            if (paginationArr[i] + 1 == result.page) {
                paginationItem.classList.add("pagination-active");
            }
            paginationItem.dataset.page = paginationArr[i] + 1;
            paginationItem.classList.add("file-uploader__pagination-item");
            paginationItem.innerText = paginationArr[i] + 1;
            element.appendChild(paginationItem);
        }
    }
    addFileListEvent() {
        const self = this;
        if (this.elements.filesList) {
            this.elements.filesList.addEventListener("click", function(e){
                if (e.target.dataset.fileUploader == "file" && e.target.dataset.type == "image" && e.target.dataset.used != "true") {
                    self.getImageInfo(e);
                    if (self.state.filesCategory == 3) {
                        self.elements.fileAdd.wrap.dataset.open = true;
                    }
                    else {
                        e.target.dataset.used = "load";
                        // self.elements.main.dataset.loading = "true";
                        self.xhrs.getResizedURL = new XHRWrap({
                            url: self.urls.resize + "?id=" + e.target.dataset.id + "&w=" + e.target.dataset.width,
                            type: "GET",
                        });
                        self.xhrs.getResizedURL.send().then(
                            result => {
                                self.addImageToArticle(result);
                                e.target.dataset.used = "true";
                                // self.elements.fileAdd.wrap.dataset.open = true;
                                // self.elements.main.dataset.loading = "false";
                                // self.elements.main.dataset.open = "false";
                            },
                            error => {
                                console.log(error);
                            }
                        )
                    }
                }
                else if (e.target.dataset.fileUploader == "file" && e.target.dataset.type == "file" && e.target.dataset.used != "true") {
                    e.target.dataset.used = "load";
                    self.addFileToArticle(e);
                    e.target.dataset.used = "true";
                }
            });
        }
    }
    getImageInfo(e) {
        this.fileToAdd.id = e.target.dataset.id;
        this.fileToAdd.width = e.target.dataset.width;
        this.fileToAdd.height = e.target.dataset.height;
        this.fileToAdd.path = e.target.dataset.path;
        this.fileToAdd.thumbnail = e.target.dataset.thumbnail;
        this.fileToAdd.categorytitle = e.target.dataset.categorytitle;
        this.fileToAdd.name = e.target.dataset.name;
        this.elements.fileAdd.width.value = e.target.dataset.width;
        this.elements.fileAdd.preview.src = e.target.dataset.path;
        this.elements.fileAdd.showDefault.href = e.target.dataset.path;

        this.elements.fileAdd.save.dataset.id = e.target.dataset.id;
        this.elements.fileAdd.save.dataset.width = e.target.dataset.width;
        this.elements.fileAdd.save.dataset.height = e.target.dataset.height;
        this.elements.fileAdd.save.dataset.path = e.target.dataset.path;
    }
    addImageToArticle(result) {
        const self = this;
        let imagesList;
        switch (parseInt(self.state.filesCategory)) {
            case 3:
                imagesList = document.querySelector("#images-article-list");
                break;
            case 2:
                imagesList = document.querySelector("#images-gallery-list");
                break;
            case 1:
                imagesList = document.querySelector("#images-lead-list");
                break;
            case "":
                console.error("Image type required");
                return;
                break;
            default:
        }
        const img = document.createElement("img");
        const link = document.createElement("a");
        const imgName = document.createElement("div");
        link.href = result;
        link.target = "_blank";
        imgName.classList.add("image__name");
        imgName.innerText = self.fileToAdd.name;

        img.src = result;
        img.alt = self.elements.fileAdd.alt.value;
        if (self.state.filesCategory == "3") {
            tinyMCE.activeEditor.selection.setContent(img.outerHTML);
        }

        const connection = self.createConnection(imagesList);
        const inputId = connection.querySelector("input[name*='[image]']");
        const ordering = imagesList.children.length;
        const inputOrdering = connection.querySelector("input[name*='[ordering]']");
        const articleId = document.querySelector("[data-article-id]").dataset.articleId;
        const inputType = connection.querySelector("input[name*='[type]']");
        const inputAlt = connection.querySelector("[name*='[alt]']");
        const inputDescription = connection.querySelector("[name*='[description]']");
        const inputSource = connection.querySelector("[name*='[source]']");
        const inputMaxWidth = connection.querySelector("[name*='[maxWidth]']");
        const popupCheckbox = connection.querySelector("[name*='[popup]']");

        const imageSource = self.elements.fileAdd.source.value;
        const imageMaxWidth = self.elements.fileAdd.width.value;

        inputId.value = self.fileToAdd.id;
        inputAlt.value = self.elements.fileAdd.alt.value;
        inputDescription.value = self.elements.fileAdd.description.value;
        inputSource.value = self.elements.fileAdd.source.value;
        inputMaxWidth.value = self.elements.fileAdd.width.value;
        popupCheckbox.checked = self.elements.fileAdd.popup.checked;

        const fieldImage = img.cloneNode(true);
        const imageWrap = connection.querySelector(".input-group.image");
        link.append(fieldImage);
        imageWrap.prepend(link);
        imageWrap.append(imgName);
        inputType.value = parseInt(self.state.filesCategory);
        inputOrdering.value = ordering;
        if (self.state.filesCategory == "3") {
            self.elements.fileAdd.wrap.dataset.open = "false";
            self.elements.main.dataset.loading = "false";
        }
    }
    addFileToArticle(e) {
        const self = this;
        let filesList = document.querySelector("#files-fields-list");
        const connection = self.createConnection(filesList);
        connection.querySelector("input[name*='[file]']").value = e.target.dataset.id;
        connection.querySelector(".files-list__name").innerText = e.target.dataset.name;
        // connection.querySelector("option[value='" + e.target.dataset.id + "']").selected = "true";
        self.setElementsOrder(filesList,"[name*='[order]']");
    }
    addPaginationEvents() {
        const self = this;
        if (this.elements.pagination) {
            this.elements.pagination.addEventListener("click", function(e){
                if (e.target.dataset.hasOwnProperty("page")) {
                    if (e.target.dataset.page == "prev") {
                        self.state.page -= 1;
                        if (self.state.page < 1) {
                            self.state.page = 1;
                        }
                    }
                    else if (e.target.dataset.page == "next") {
                        self.state.page += 1;
                    }
                    else {
                        self.state.page = e.target.dataset.page;
                    }

                    self.elements.main.dataset.loading = "true";
                    self.loadImages();
                    Promise.all(self.state.promises).then(
                        result => {
                            self.elements.main.dataset.loading = "false";
                        },
                        error => {
                            console.log(error);
                        }
                    )
                }
            });
        }
    }
    addFormEvents() {
        const self = this;
        if (this.elements.form) {
            this.elements.form.addEventListener("change", function(e){
                if (e.target.dataset.hasOwnProperty("fileUploader") && e.target.dataset.fileUploader == "list-limit") {
                    self.elements.main.dataset.loading = "true";
                    self.state.listLimit = e.target.value;
                    self.loadImages();
                    Promise.all(self.state.promises).then(
                        result => {
                            self.elements.main.dataset.loading = "false";
                        },
                        error => {
                            console.log(error);
                        }
                    )
                }
            });
        }
        if (this.elements.finder.submit) {
            this.elements.finder.submit.addEventListener("click", function(e){
                self.elements.main.dataset.loading = "true";
                self.getData();
                self.loadImages();
                Promise.all(self.state.promises).then(
                    result => {
                        self.elements.main.dataset.loading = "false";
                    },
                    error => {
                        console.log(error);
                    }
                )
            });
        }
        if (self.elements.finder.wrap) {
            let finderInputs = self.elements.finder.wrap.querySelectorAll("input");
            for (var i = 0; i < finderInputs.length; i++) {
                finderInputs[i].addEventListener("keypress", function(e){
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        e.stopPropagation();
                        self.elements.main.dataset.loading = "true";
                        self.getData();
                        self.loadImages();
                        Promise.all(self.state.promises).then(
                            result => {
                                self.elements.main.dataset.loading = "false";
                            },
                            error => {
                                console.log(error);
                            }
                        )
                    }
                });
            }
        }
        if (self.elements.finder.toggle) {
            self.elements.finder.toggle.addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                if (self.elements.finder.wrap.dataset.open == "true") {
                    self.elements.finder.wrap.dataset.open = "false";
                    let finderInputs = self.elements.finder.wrap.querySelectorAll("input");
                    for (var i = 0; i < finderInputs.length; i++) {
                        finderInputs[i].value = "";
                    }
                    self.elements.main.dataset.loading = "true";
                    self.getData();
                    self.loadImages();
                    Promise.all(self.state.promises).then(
                        result => {
                            self.elements.main.dataset.loading = "false";
                        },
                        error => {
                            console.log(error);
                        }
                    )
                }
                else {
                    self.elements.finder.wrap.dataset.open = "true";
                }
            });
        }
        if (self.elements.fileAdd.save) {
            self.elements.fileAdd.save.addEventListener("click", function(e){
                self.elements.main.dataset.loading = "true";
                self.xhrs.getResizedURL = new XHRWrap({
                    url: self.urls.resize + "?id=" + e.target.dataset.id + "&w=" + self.elements.fileAdd.width.value,
                    type: "GET",
                });
                self.xhrs.getResizedURL.send().then(
                    result => {
                        self.addImageToArticle(result);
                        self.elements.fileAdd.wrap.dataset.open = "false";
                        self.elements.main.dataset.loading = "false";
                        self.elements.main.dataset.open = "false";
                    },
                    error => {
                        console.log(error);
                    }
                )
            });
        }
    }
    addButtonEvents() {
        const self = this;
        if (self.state.buttonSelector) {
            const btns = document.querySelectorAll(self.state.buttonSelector);
            if (btns.length > 0) {
                for (var i = 0; i < btns.length; i++) {
                    btns[i].addEventListener("click", function(e){
                        e.preventDefault();
                        e.stopPropagation();
                        self.elements.main.dataset.loading = "true";
                        self.state.filesCategory = e.target.dataset.fileType;
                        self.elements.main.dataset.open = "true";
                        self.loadFolders();
                        self.loadImages();
                        Promise.all(self.state.promises).then(
                            result => {
                                self.state.loading = false;
                                self.elements.main.dataset.loading = "false";
                            }
                        )
                    });
                }
            }
        }
    }
    addSettingsEvents() {
        const self = this;
        if (self.elements.settings.wrap) {
            self.elements.settings.wrap.addEventListener("click", function(e){
                if (e.target.dataset.fileUploader == "settings-btn") {
                    self.elements.settings.wrap.classList.toggle("open");
                }
                if (e.target.dataset.fileUploader == "settings-option") {
                    self.elements.settings.wrap.classList.toggle("option-selected");
                    e.target.parentElement.classList.toggle("selected");
                }
                if (e.target.dataset.fileUploader == "settings-option-value") {
                    const option = e.target.closest("[data-option]");
                    if (option) {
                        const optionName = option.dataset.option;
                        if (optionName == "theme") {
                            self.elements.mainWrap.dataset.theme = e.target.dataset.value;
                            option.dataset.selected = e.target.dataset.selected;
                            self.setCookie("file-uploader-theme", e.target.dataset.value, 365, "/");
                        }
                        if (optionName == "layout") {
                            self.elements.filesList.dataset.layout = e.target.dataset.value;
                            option.dataset.selected = e.target.dataset.selected;
                            self.setCookie("file-uploader-layout-" + self.state.contentType, e.target.dataset.value, 365, "/");
                        }
                    }
                }
            });
        }
    }
    setCookie(name, val, days, path, domain, secure) {
        if (navigator.cookieEnabled) {
            const cookieName = encodeURIComponent(name);
            const cookieVal = encodeURIComponent(val);
            let cookieText = cookieName + "=" + cookieVal;
            if (typeof days === "number") {
                const data = new Date();
                data.setTime(data.getTime() + (days * 24*60*60*1000));
                cookieText += "; expires=" + data.toGMTString();
            }
            if (path) {
                cookieText += "; path=" + path;
            }
            if (domain) {
                cookieText += "; domain=" + domain;
            }
            if (secure) {
                cookieText += "; secure";
            }
            document.cookie = cookieText;
        }
    }
    showCookie(name) {
        if (document.cookie !== "") {
            const cookies = document.cookie.split(/; */);
            for (let i=0; i<cookies.length; i++) {
                const cookiePart = cookies[i].split("=");
                const cookieName = cookiePart[0];
                const cookieVal = cookiePart[1];
                if (cookieName === decodeURIComponent(name)) {
                    return decodeURIComponent(cookieVal);
                }
            }
        }
        return false;
    }
    getUserSettings() {
        const self = this;
        if (self.elements.settings.wrap) {
            // theme
            if (self.showCookie("file-uploader-theme")) {
                const theme = self.showCookie("file-uploader-theme");
                const option = self.elements.settings.options.querySelector("[data-option='theme']");
                const value = option.querySelector("[data-value='" + theme + "']");
                option.dataset.selected = value.dataset.selected;
                self.elements.mainWrap.dataset.theme = theme;
            }

            if (self.showCookie("file-uploader-layout-" + self.state.contentType)) {
                console.log("1");
                const layout = self.showCookie("file-uploader-layout-" + self.state.contentType);
                const option = self.elements.settings.options.querySelector("[data-option='layout']");
                const value = option.querySelector("[data-value='" + layout + "']");
                option.dataset.selected = value.dataset.selected;
                self.elements.filesList.dataset.layout = layout;
            }
        }
    }
    init() {
        const self = this;
        this.getElements();
        this.runDuplicateController();
        this.getData();
        this.addFormEvents();
        this.addPaginationEvents();
        this.setCustomScroll();
        this.addFileListEvent();
        this.addButtonEvents();
        this.setSortable();
        this.addMenuEvent();
        this.addUploadEvent();
        this.addFoldersListEvent();
        this.addCloseEvents();
        this.addSettingsEvents();
        this.getUserSettings();
        console.log(this);
    }
}

export default FileUploader;
