const images = (XHRWrap, DuplicateController, Simplebar) => {

    const imagesList = document.querySelectorAll("ul[data-list-type]");
    let duplicateController = new DuplicateController(imagesList);

    var fileLoader = document.querySelector(".file-loader");
    var fileInput = document.getElementById("newFile");


    const imageFinder = document.querySelector(".avt-image-popup__finder");
    const finderInputs = document.querySelectorAll(".avt-image-popup__finder-input");

    const getFileList = new XHRWrap({
        url: "/admin/api/v1/categories-tree?type=5",
        type: "GET",
    });
    function loadImagesList(page = 0, url = "/admin/api/v1/images?category=" + imagePopupCategories.dataset.selected + "&limit=" + listLimit.value + "&page=" + page + "&name=" + finderInputs[0].value.trim() + "&contentTitle=" + finderInputs[1].value.trim() + "&contentId=" + finderInputs[2].value.trim()) {
        imagePopup.dataset.loading = "true";
        loadImages = new XHRWrap({
            url: url,
            type: "GET",
        });
        loadImages.send().then(
            result => {
                createPagination(result, popupPaginationItems);
                imagePopupList.innerHTML = result.html;
                let images = imagePopupList.querySelectorAll(".file-watcher__file[data-id]");
                duplicateController.setUsedImages(images, imagePopup.dataset.imageType);
                getFileList.send().then(
                    result => {
                        if (filesList) {
                            filesList.innerHTML = result.html;
                            let selectedId = imagePopupCategories.dataset.selected;
                            let oldActive = imagePopupCategories.querySelector(".active");
                            if (oldActive) {
                                oldActive.classList.remove("active");
                            }
                            let selectedItem = imagePopupCategories.querySelector("[data-category-id='" + selectedId + "']").parentElement;
                            selectedItem.classList.add("active");
                        }
                        fileLoader.classList.remove("loading");
                        imagePopup.dataset.loading = "false";
                    },
                    error => {
                        console.error(error);
                        alert(error);
                        fileLoader.classList.remove("loading");
                        imagePopup.dataset.loading = "false";
                    }
                )
            },
            error => {
                console.error(error);
                alert(error);
            }
        )
    }
    function createPagination(result, element) {
        console.log(result);
        if (result.hasOwnProperty("pages") && result.hasOwnProperty("page")) {
            element.innerHTML = "";
            element.parentElement.dataset.state = "";
            let resultPage = result.page;
            let resultPages = result.pages;
            if (resultPages > 0) {
                if (resultPage == 0) {
                    resultPage+= 1;
                }
                let startPage = resultPage;
                let limit = resultPage + 8;
                if (limit >= resultPages) {
                    limit = resultPages + 1;
                }

                if (resultPages > 8) {
                    if (startPage + 8 > resultPages) {
                        startPage = startPage - (startPage + 8 - resultPages);
                        let paginationItem = document.createElement("div");
                        paginationItem.innerText = 1;
                        paginationItem.dataset.page = 1;
                        paginationItem.classList.add("avt-image-popup__pagination-item");
                        element.appendChild(paginationItem);

                        paginationItem = document.createElement("div");
                        paginationItem.innerText = "...";
                        paginationItem.classList.add("avt-image-popup__pagination-item");
                        paginationItem.classList.add("avt-image-popup__pagination-item--dots");
                        element.appendChild(paginationItem);
                    }
                }
                else {
                    startPage = 1;
                }

                for (var i = startPage; i < limit; i++) {
                    let paginationItem = document.createElement("div");
                    paginationItem.dataset.page = i;
                    paginationItem.classList.add("avt-image-popup__pagination-item");
                    paginationItem.innerText = i;
                    if (resultPage == 1) {
                        element.parentElement.dataset.state = "first";
                    }
                    if (resultPage == i) {
                        paginationItem.classList.add("pagination-active");
                    }
                    if (resultPage == resultPages) {
                        element.parentElement.dataset.state = "last";
                    }
                    element.appendChild(paginationItem);
                }
            }
            else {
                let paginationItem = document.createElement("div");
                paginationItem.dataset.page = 1;
                paginationItem.classList.add("pagination-active");
                paginationItem.classList.add("avt-image-popup__pagination-item");
                paginationItem.innerText = 1;
                element.parentElement.dataset.state = "one";
                element.appendChild(paginationItem);
            }
            if (resultPages > 8 && resultPage + 8 < resultPages) {
                let paginationItem = document.createElement("div");
                paginationItem.innerText = "...";
                paginationItem.classList.add("avt-image-popup__pagination-item");
                paginationItem.classList.add("avt-image-popup__pagination-item--dots");
                element.appendChild(paginationItem);

                paginationItem = document.createElement("div");
                paginationItem.dataset.page = resultPages;
                paginationItem.classList.add("avt-image-popup__pagination-item");
                paginationItem.innerText = resultPages;
                element.appendChild(paginationItem);
            }
        }
        else {
            return;
        }
    }

    function createConnection(list) {
        list = jQuery(list);
        const countImages = document.querySelector("[data-count-images]");
        // Try to find the counter of the list or use the length of the list
        var counter = parseInt(countImages.dataset.countImages);

        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Increase the counter
        // And store it, the length cannot be used if deleting widgets is allowed
        counter++;
        list.data('widget-counter', counter);
        countImages.dataset.countImages = counter;
        // create a new list element and add it to the list
        var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);

        newElem.appendTo(list);
        return newElem;
    }


    if (fileLoader && fileInput) {
        fileInput.addEventListener("change", function(event) {
    		var filesArr = [];
    		var form = document.querySelector("[name='adminForm']");
    		var files = document.querySelector("#newFile").files;
    		var uploadList = document.querySelector(".file-loader__list");
    		var uploadListWrap = document.querySelector(".file-loader__list-wrap");
    		var uploadName = document.querySelector(".file-loader__progress-file");
    		// var categoriesId = document.querySelector("#jform_img_upload_category").value;
    		// var sectionId = document.querySelector('#jform_img_upload_select_<?= $this->type ?>').value;
    		var progressBar = document.querySelector(".file-loader__progress-bar");
    		var progressCounter = document.querySelector(".file-loader__progress-counter");
    		var listCount = document.querySelector(".file-loader__count");
    		var listCountMax = document.querySelector(".file-loader__count-max");
    		uploadList.innerHTML = "";
    		uploadName.textContent = "";
    		uploadListWrap.classList.add("active");
    		for (var i = 0; i < files.length; i++) {
    			filesArr.push(files[i]);
    			var listElement = document.createElement("li");
    			listElement.classList.add("file-loader__list-item");
    			listElement.classList.add("icon-file");
    			listElement.textContent = files[i].name;
    			uploadList.appendChild(listElement);
    		}
    		var uploadElements = document.querySelectorAll(".file-loader__list-item");
    		listCount.textContent = 0;
    		listCountMax.textContent = files.length;
    		filesArr.reduce( (p, _, i) =>
    		    p.then(_ => new Promise(resolve =>
    		        setTimeout(function () {
    					fileLoader.classList.add("loading");
    					uploadElements[i].classList.add("upload");
    					uploadElements[i].classList.remove("icon-file");
    					uploadElements[i].classList.add("icon-upload");

    					progressBar.style.right = "100%";
    					uploadName.textContent = filesArr[i].name;

    					var newData = new FormData();
    					newData.delete("file");
    					// newData.delete("categories_id");
    					newData.set("file", filesArr[i]);
    					// newData.set("categories_id", categoriesId);

    					var xhttp = new XMLHttpRequest();
    					xhttp.upload.onprogress = function(e){
    						var pc = parseInt(100 - (e.loaded / e.total * 100));
    						var percent = parseInt(e.loaded / e.total * 100);
    						progressBar.style.right = pc + "%";
    						progressCounter.textContent = percent + "%";
    					};
                        let uElement = uploadElements[i];
    					xhttp.onload = function() {
                            if (xhttp.status == 201) {
                                var resArr = JSON.parse(xhttp.response);
                                if (resArr.success == false || resArr == false) {
                                    uElement.classList.add("icon-cancel");
                                    uElement.classList.remove("icon-upload");
                                    uElement.classList.add("response");
                                    uElement.innerHTML += "<div class='file-loader__list-item--message'>" + resArr.message + "</div>";
                                    console.error("Abort", xhttp.responseText);
                                }
                                else {
                                    progressBar.style.right = "100%";
                                    listCount.textContent = parseInt(listCount.textContent) + 1;
                                }
                            }
                            else {
                                console.error(xhttp);
                            }
    					};
    					xhttp.onloadend = function() {
    						uploadElements[i].classList.remove("icon-upload");
    						uploadElements[i].classList.add("icon-save");
    						uploadElements[i].classList.add("response");
    						resolve();
    						if (i == filesArr.length - 1) {
    							fileLoader.classList.remove("loading");
    						}
    					};
    					xhttp.onabort = function () {
    						uploadElements[i].classList.add("icon-cancel");
    						uploadElements[i].classList.add("response");
    						console.error("Abort", xhttp.responseText);
    					};
    					xhttp.open("POST", "/admin/api/v1/image-upload?category=" + imagePopupCategories.dataset.selected, true);
    				    xhttp.send(newData);
    		        }, 1)
    		    ))
    		, Promise.resolve() );
    	});
    }

    var allImg = document.querySelectorAll(".file-watcher__file.--article");
    if (allImg.length > 0) {
        var popup = document.querySelector(".file-watcher__popup");
        var popupImg = popup.querySelector(".file-watcher__popup-image");
        var popupAdd = popup.querySelector(".file-watcher__popup-add");
        var inputWidth = popup.querySelector(".file-watcher__popup-input--width");
        var inputAlt = popup.querySelector(".file-watcher__popup-input--alt");
        var inputDescr = popup.querySelector(".file-watcher__popup-input--description");
        var inputSource = popup.querySelector(".file-watcher__popup-input--source");
        var popupShow = popup.querySelector(".file-watcher__show-default");
        var popupCheck = popup.querySelector(".file-watcher__popup-checkbox");
        for (var i = 0; i < allImg.length; i++) {
            allImg[i].addEventListener("click", function(){
                popup.dataset.open = "true";
                popupImg.style.backgroundImage = "url(" + this.dataset.path + ")";
                popupAdd.dataset.id = this.dataset.id;
                popupAdd.dataset.width = this.dataset.width;
                popupAdd.dataset.height = this.dataset.height;
                popupAdd.dataset.path = this.dataset.path;
                popupAdd.dataset.thumbnail = this.dataset.thumbnail;
                popupAdd.dataset.categorytitle = this.dataset.categorytitle;
                inputWidth.value = this.dataset.width;
                popupShow.href = this.dataset.path;
            });
        }
        popup.addEventListener("click", function(event){
            if (event.target.className == "file-watcher__popup-close") {
                this.dataset.open = "false";
            }
        });
        popupAdd.addEventListener("click", function(event){
            event.preventDefault();
            var popupChecked = popupCheck.checked;
            var width = inputWidth.value;
            var description = inputDescr.value;
            var alt = inputAlt.value;
            var source = inputSource.value;

            if (popupChecked == true) {
                popupChecked = 1;
            } else {
                popupChecked = 0;
            }

            var img = {
                id: this.dataset.id,
                thumbnail: this.dataset.thumbnail,
                connections_id: '',
                category_title: this.dataset.categorytitle,
                params: JSON.stringify({
                    "title": alt,
                    "description": description,
                    "zrodlo": source,
                    "width": width,
                    "popup": popupChecked
                }),
                width: popupAdd.dataset.width,
                height: popupAdd.dataset.height,
                path: popupAdd.dataset.path
            };
            window.parent.eval('avtImage' + window.parent.avtImgConnectionId).parentFunction(img);

            window.parent.addResizedImgHtml(this.dataset.id, width, alt, description, source, popupChecked);
        })
    }

    var files = document.querySelectorAll('.file-watcher__file.--gallery');
    for (var i = 0; i < files.length; i++) {
        if (files[i].dataset.function) {
            files[i].addEventListener('click', function() {
                this.classList.add("added");
                var img = {
                    id: this.dataset.id,
                    thumbnail: this.dataset.thumbnail,
                    connections_id: '',
                    category_title: this.dataset.categorytitle,
                    params: JSON.stringify({
                        "title": "",
                        "description": "",
                        "zrodlo": ""
                    }),
                    width: this.dataset.width,
                    height: this.dataset.height,
                    path: this.dataset.path
                };
                window.parent.eval('avtImage' + this.dataset.function).parentFunction(img);
            });
        }
    }

    var hideBtn = document.querySelector(".js-hide-explorer");
    var imagesRow = document.querySelector(".images-row");
    if (hideBtn) {
        hideBtn.addEventListener("click", function(){
            var dataExplorer = imagesRow.dataset.explorer;
            if (dataExplorer == "true") {
                imagesRow.dataset.explorer = "false";
            }
            else {
                imagesRow.dataset.explorer = "true";
            }
        });
    }





    const imagePopup = document.getElementsByClassName("avt-image-popup")[0];
    const imagePopupBtn = document.getElementsByClassName("editor-image-btn")[0];
    const imagePopupClose = document.getElementsByClassName("avt-image-popup__close")[0];
    const body = document.querySelector("body");
    const imagePopupMenu = document.getElementsByClassName("avt-image-popup__menu")[0];
    const imagePopupContent = document.getElementsByClassName("avt-image-popup__content")[0];
    const imagePopupList = document.querySelector(".avt-image-popup__list");
    const imagePopupBtns = document.getElementsByClassName("js-upload-images");
    const imagePopupCategories = document.querySelector(".avt-image-popup .categories-list");
    const popupPaginationItems = document.querySelector(".avt-image-popup__pagination-items");
    const listLimit = document.querySelector(".avt-image-popup__list-limit select");

    let loadImages;
    if (imagePopupContent) {
        const imageScroll = new Simplebar(imagePopupContent, {
            autoHide: false,
        });

    }
    if (listLimit) {
        listLimit.addEventListener("change", function(e){
            loadImagesList();
        });
    }
    if (popupPaginationItems) {
        popupPaginationItems.parentElement.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("page")) {
                let page = e.target.dataset.page;
                let paginationActive = document.querySelector(".pagination-active");
                if (paginationActive) {
                    if (page == "next") {
                        page = parseInt(paginationActive.dataset.page) + 1;
                    }
                    else if (page == "prev") {
                        page = parseInt(paginationActive.dataset.page) - 1;
                    }
                }
                else if (typeof(parseInt(page)) == "number") {
                    page = parseInt(e.target.dataset.page);
                }
                loadImagesList(page);
            }
        });
    }
    if (imagePopupBtn) {
        imagePopupBtn.addEventListener("click", function(e){
            e.preventDefault();
            e.stopPropagation();
            imagePopupList.innerHTML = "";
            imagePopup.classList.add("open");
            body.classList.add("popup-open");
            loadImagesList();
        });
    }
    if (imagePopupBtns.length > 0) {
        for (var i = 0; i < imagePopupBtns.length; i++) {
            imagePopupBtns[i].addEventListener("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                imagePopup.classList.add("open");
                imagePopup.dataset.imageType = this.dataset.imageType;
                body.classList.add("popup-open");
                imagePopupList.innerHTML = "";
                loadImagesList();
            });
        }
    }
    if (imagePopupClose) {
        imagePopupClose.addEventListener("click", function(e){
            e.preventDefault();
            e.stopPropagation();
            imagePopup.classList.remove("open");
            body.classList.remove("popup-open");
        });
    }
    if (imagePopupMenu) {
        imagePopupMenu.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("contentMenu")) {
                e.preventDefault();
                imagePopupContent.dataset.content = e.target.dataset.contentMenu;
                if (e.target.dataset.contentMenu == "list") {
                    imagePopupList.innerHTML = "";
                    imagePopup.dataset.loading = "true";
                    loadImages = new XHRWrap({
                        url: "/admin/api/v1/images?category=" + imagePopupCategories.dataset.selected + "&limit=" + listLimit.value,
                        type: "GET"
                    });
                    loadImages.send().then(
                        result => {
                            createPagination(result, popupPaginationItems);
                            imagePopupList.innerHTML = result.html;
                            imagePopup.dataset.loading = "false";
                            let images = imagePopupList.querySelectorAll(".file-watcher__file[data-id]");
                            duplicateController.setUsedImages(images, imagePopup.dataset.imageType);
                        },
                        error => {
                            console.error(error);
                            alert(error);
                        }
                    )
                }
            }
        });
    }
    var imgPopup = document.querySelector(".file-watcher__popup--main");
    if (imgPopup) {
        var popupWidth = imgPopup.querySelector(".file-watcher__popup-input--width");
        var popupAlt = imgPopup.querySelector(".file-watcher__popup-input--alt");
        var popupDescr = imgPopup.querySelector(".file-watcher__popup-input--description");
        var popupSource = imgPopup.querySelector(".file-watcher__popup-input--source");
        var popupCheck = imgPopup.querySelector(".file-watcher__popup-checkbox");
        var popupClose = imgPopup.querySelector(".file-watcher__popup-close");
        var popupImg = imgPopup.querySelector(".file-watcher__popup-img");
        var popupSend = imgPopup.querySelector(".file-watcher__popup-add");
        var popupAdd = imgPopup.querySelector(".file-watcher__popup-add");
        var inputWidth = imgPopup.querySelector(".file-watcher__popup-input--width");
        imagePopupList.addEventListener("click", function(e){
            if (e.target.dataset.edit == "imgPopup" && e.target.dataset.used != "true") {
                imgPopup.dataset.id = e.target.dataset.id;
                imgPopup.dataset.width = e.target.dataset.width;
                imgPopup.dataset.height = e.target.dataset.height;



                popupImg.src = e.target.dataset.path;
                popupAdd.dataset.id = e.target.dataset.id;
                popupAdd.dataset.width = e.target.dataset.width;
                popupAdd.dataset.height = e.target.dataset.height;
                popupAdd.dataset.path = e.target.dataset.path;
                popupAdd.dataset.thumbnail = e.target.dataset.thumbnail;
                popupAdd.dataset.categorytitle = e.target.dataset.categorytitle;
                popupAdd.dataset.name = e.target.dataset.name;
                inputWidth.value = e.target.dataset.width;

                if (imagePopup.dataset.imageType == 3) {
                    imgPopup.dataset.open = "true";
                }
                else {
                    e.target.dataset.used = "true";
                    popupSend.click();
                }
            }
        });
        popupClose.addEventListener("click", function(){
            imgPopup.dataset.open = "false";
        });
        popupSend.addEventListener("click", function(e){
            e.preventDefault();
            let id = imgPopup.dataset.id;
            let height = imgPopup.dataset.height;
            let width = popupWidth.value;
            let url = "/admin/api/v1/resizeimage?id=" + id + "&w=" + width;
            let getUrl = new XHRWrap({
                url: url,
                type: "GET"
            });
            let imagesList;
            switch (imagePopup.dataset.imageType) {
                case "3":
                    imagesList = document.getElementById("images-article-list");
                    break;
                case "2":
                    imagesList = document.getElementById("images-gallery-list");
                    break;
                case "1":
                    imagesList = document.getElementById("images-lead-list");
                    break;
                case "":
                    console.error("Image type required");
                    return;
                    break;
                default:
            }
            getUrl.send().then(
                result => {
                    if (result != "") {
                        const img = document.createElement("img");
                        const link = document.createElement("a");
                        const imgName = document.createElement("div");
                        link.href = result;
                        link.target = "_blank";
                        imgName.classList.add("image__name");
                        imgName.innerText = popupAdd.dataset.name;
                        img.src = result;
                        img.alt = popupAlt.value;
                        if (imagePopup.dataset.imageType == "3") {
                            tinyMCE.activeEditor.selection.setContent(img.outerHTML);
                        }


                        const connection = createConnection(imagesList);
                        const inputId = connection.find("input[name$='[image]']");
                        inputId.val(id);
                        const ordering = imagesList.children.length;
                        const inputOrdering = connection.find("input[name$='[ordering]']");
                        const articleId = document.querySelector("[data-article-id]").dataset.articleId;
                        const inputContent = connection.find("input[name$='[content]']");
                        const inputType = connection.find("input[name$='[type]']");

                        const inputAlt = connection.find("[name$='[alt]']");
                        const inputDescription = connection.find("[name$='[description]']");
                        const inputSource = connection.find("[name$='[source]']");
                        const inputMaxWidth = connection.find("[name$='[maxWidth]']");

                        const imageAlt = popupAlt.value;
                        const imageDescription = popupDescr.value;
                        const imageSource = popupSource.value;
                        const imageMaxWidth = popupWidth.value;

                        inputAlt.val(imageAlt);
                        inputDescription.val(imageDescription);
                        inputSource.val(imageSource);
                        inputMaxWidth.val(imageMaxWidth);

                        const fieldImage = img.cloneNode(true);
                        const imageWrap = connection.find(".input-group.image");
                        link.append(fieldImage);
                        imageWrap.prepend(link);
                        imageWrap.append(imgName);
                        inputType.val(parseInt(imagePopup.dataset.imageType));
                        inputOrdering.val(ordering);
                        inputContent.val(articleId);
                        if (imagePopup.dataset.imageType == 3) {
                            imgPopup.dataset.open = "false";
                            imagePopup.classList.remove("open");
                            body.classList.remove("popup-open");
                            imagePopup.dataset.imageType = "";
                        }
                    }
                },
                error => {
                    console.error(error);
                    alert(error);
                }
            )
        });
    }


    // Sort
    function setImagesOrder(list) {
        const elements = list.children;
        for (var i = 0; i < elements.length; i++) {
            let input = elements[i].querySelector("[name$='[ordering]']");
            input.value = i + 1;
        }
    }
    const galleryList = document.getElementById('images-gallery-list');
    if (galleryList) {
        const gallerySort = new Sortable(galleryList, {
            swapThreshold: 1,
            animation: 150,
            sort: true,
            handle: '.drag-wrapper',
            onSort: function () {
                setImagesOrder(galleryList);
            }
        });
    }
    const leadList = document.getElementById('images-lead-list');
    if (leadList) {
        const leadSort = new Sortable(leadList, {
            swapThreshold: 1,
            animation: 150,
            sort: true,
            handle: '.drag-wrapper',
            onSort: function () {
                setImagesOrder(leadList);
            }
        });
    }
    const jsImageList = document.getElementsByClassName("js-image-list");
    if (jsImageList.length > 0) {
        for (var i = 0; i < jsImageList.length; i++) {
            jsImageList[i].addEventListener("click", function(e){
                if (e.target.classList.contains("remove-image-element")) {
                    let parent = e.target.parentElement.parentElement;
                    if (parent.classList.contains("images-list__item")) {
                        parent.remove();
                    }
                    parent = "";
                }
            });
        }
    }

    var dropzone = document.getElementById("content_text_value_ifr");
    // Block drag and drop
    document.addEventListener("dragstart", function(e) {
        console.log(e);
        if (e.target.nodeName.toUpperCase() == "IMG") {
            e.preventDefault();
            return false;
        }
    });

    if (dropzone) {

        dropzone.addEventListener("dragenter", function(e) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        }, false);

        dropzone.addEventListener("dragover", function(e) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        });

        dropzone.addEventListener("drop", function(e) {
            e.preventDefault();
            e.dataTransfer.effectAllowed = "none";
            e.dataTransfer.dropEffect = "none";
        });
    }

    const filesList = document.querySelector(".js-files-list");
    if (filesList) {
        getFileList.send().then(
            result => {
                if (filesList) {
                    filesList.innerHTML = result.html;
                    let selectedId = imagePopupCategories.dataset.selected;
                    let oldActive = imagePopupCategories.querySelector(".active");
                    if (oldActive) {
                        oldActive.classList.remove("active");
                    }
                    let selectedItem = imagePopupCategories.querySelector("[data-category-id='" + selectedId + "']").parentElement;
                    selectedItem.classList.add("active");
                }
            },
            error => {
                console.error(error);
                alert(error);
            }
        )
        filesList.addEventListener("click", function(event){
            if (event.target.classList.contains("js-add-cat")) {
                event.preventDefault();
                event.stopPropagation();
                var newId = event.target.dataset.id;
                var newList = document.createElement("ul");
                var newListEl = document.createElement("li");
                var newLink = document.createElement("a");
                var folderForm = document.createElement("form");
                var folderSend = document.createElement("input");
                var folderName = document.createElement("input");
                folderName.type = "text";
                folderName.dataset.close = "true";
                folderSend.type = "submit";
                folderSend.dataset.close = "true";
                folderSend.value = "";
                folderName.placeholder = "Nazwa folderu";
                newList.appendChild(newListEl);
                folderForm.appendChild(folderName);
                folderForm.appendChild(folderSend);
                newListEl.appendChild(folderForm);
                event.target.parentElement.parentElement.appendChild(newList);
                folderName.focus();
                document.addEventListener("click", function(event){
                    if (event.target.dataset.close != "true") {
                        newList.remove();
                    }
                });
                folderForm.addEventListener("submit", function(event){
                    event.preventDefault();
                    fileLoader.classList.add("loading");
                    imagePopup.dataset.loading = "true";
                    var catName = folderName.value;
                    var link = "http://elportal.avt.pl/admin/api/v1/category-add?name=" + catName + "&type=5&parent=" + newId;

                    const addFolder = new XHRWrap({
                        url: link,
                        type: "POST"
                    });
                    addFolder.send().then(
                        result => {
                            getFileList.send().then(
                                result => {
                                    if (filesList) {
                                        filesList.innerHTML = result.html;

                                        let selectedId = imagePopupCategories.dataset.selected;
                                        let oldActive = imagePopupCategories.querySelector(".active");
                                        if (oldActive) {
                                            oldActive.classList.remove("active");
                                        }
                                        let selectedItem = imagePopupCategories.querySelector("[data-category-id='" + selectedId + "']").parentElement;

                                        selectedItem.classList.add("active");
                                    }
                                    fileLoader.classList.remove("loading");
                                    imagePopup.dataset.loading = "false";
                                },
                                error => {
                                    console.error(error);
                                    alert(error);
                                    fileLoader.classList.remove("loading");
                                    imagePopup.dataset.loading = "false";
                                }
                            )
                        },
                        error => {
                            console.error(error);
                            alert(error);
                        }
                    );

                });
            }
            if (event.target.dataset.hasOwnProperty("categoryId")) {
                event.preventDefault();
                imagePopupCategories.dataset.selected = event.target.dataset.categoryId;
                loadImagesList();
            }
        });
    }

    if (imageFinder) {
        let finderActive = false;
        const finderSubmit = imageFinder.querySelector(".avt-image-popup__finder-submit")
        const editForm = finderInputs[0].closest("form");
        for (var i = 0; i < finderInputs.length; i++) {
            finderInputs[i].addEventListener("keypress", function(e){
                if (e.keyCode == 13) {
                    e.preventDefault();
                    e.stopPropagation();
                    let url = "/admin/api/v1/images?category=" + imagePopupCategories.dataset.selected + "&limit=" + listLimit.value + "&page=" + 0
                    url += "&name=" + finderInputs[0].value.trim() + "&contentTitle=" + finderInputs[1].value.trim() + "&contentId=" + finderInputs[2].value.trim();
                    loadImagesList(0, url);
                }
            });
        }
        imageFinder.addEventListener("click", function(e){
            if (e.target.classList.contains("avt-image-popup__finder-toggle")) {
                if (this.dataset.finder == "close") {
                    this.dataset.finder = "open";
                }
                else {
                    this.dataset.finder = "close";
                    for (var i = 0; i < finderInputs.length; i++) {
                        finderInputs[i].value = "";
                    }
                    loadImagesList("");
                }
            }
        })
        finderSubmit.addEventListener("click", function(e){
            loadImagesList("");
        });
    }
}
export default images;
