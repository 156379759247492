class DuplicateController {
    constructor(items, selector = "input[name*='[image]']") {
        this.items = items;
        this.itemsId = {};
        this.selector = selector;
        this.init();
    }
    getItemsId() {
        let items = this.items;
        for (var i = 0; i < items.length; i++) {
            let key = items[i].dataset.listType;
            let files = items[i].querySelectorAll(this.selector);
            let ids = [];
            for (var j = 0; j < files.length; j++) {
                ids.push(files[j].value);
            }
            let item = Object.defineProperty(this.itemsId, key, {
                value: ids,
                writable: true
            });
        }
    }
    setUsedImages(files, type) {
        this.getItemsId();
        let idsArray = this.itemsId[type];
        for (var i = 0; i < files.length; i++) {
            if (idsArray.indexOf(files[i].dataset.id) != -1) {
                files[i].dataset.used = "true";
            }
        }
    }
    init() {
        this.getItemsId();
    }
}

export default DuplicateController;
