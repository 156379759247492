class XHRWrap {
    constructor(settings = {}) {
        this.xhr = new XMLHttpRequest();
        this.type = "POST";
        this.async = true;
        this.url = null;
        this.data = null;
        this.form = null;
        this.formData = null;
        this.xhrType = "json";
        this.additionalFormData = null;
        this.html;
        this.json;
        this.response;
        this.requestHeader = {
            header: "Content-Type",
            value: "application/json",
        };
        this.messageEl;
        Object.assign(this,settings);
        this.init();
    }
    getFormData() {
        if (this.form == null) {
            console.error("Form is null");
            return false;
        }
        if (typeof(this.form) == "string") {
            this.form = document.querySelector(this.form);
        }
        else if (typeof(this.form) != "object") {
            console.error("Form element is incorrect");
            return false;
        }
        let inputs = this.form.querySelectorAll("[data-form-data]");
        this.formData = new FormData();
        for (let i = 0; i < inputs.length; i++) {
            let key;
            let value;
            if ((inputs[i].type == "radio" || inputs[i].type == "checkbox") && !inputs[i].checked) {
                continue;
            }
            else if ((inputs[i].type == "radio" || inputs[i].type == "checkbox") && inputs[i].checked) {
                key = inputs[i].name;
                value = inputs[i].value;
            }
            else {
                key = inputs[i].name;
                value = inputs[i].value;
            }
            if (typeof(key) != "undefined" && typeof(value) != "undefined") {
                this.formData.append(key, value);
            }
        }
        if (this.additionalFormData != null) {
            for (var data in this.additionalFormData) {
                if (this.additionalFormData.hasOwnProperty(data)) {
                    this.formData.append(data, this.additionalFormData[data]);
                }
            }
        }
        return this.formData;
    }
    send() {
        const self = this;
        if (this.url == null) {
            reject(new Error("URL is null"));
            return;
        }
        else {
            return new Promise(function(resolve, reject) {

                self.xhr.addEventListener("load", function(){
                    if (self.xhr.status >= 200 && self.xhr.status < 300) {
                        let res;
                        if (self.xhrType == "json") {
                            try {
                                res = JSON.parse(self.xhr.response);
                                self.json = res;
                            }
                            catch(error) {
                                reject(error);
                            }
                            if (typeof(res) != "undefined") {
                                self.html = res.html;
                                resolve(res);
                            }
                            else {
                                self.html = null;
                                reject(new Error("Response HTML is undefined"));
                            }
                        }
                        else {
                            self.response = res;
                            resolve(res);
                        }
                    }

                    else {
                        console.error(self.xhr.statusText);
                        reject(new Error(self.xhr.statusText));
                    }

                });
                self.xhr.addEventListener("error", function(){
                    console.error(self.xhr.statusText);
                    reject(new Error(self.xhr.statusText));
                });
                self.xhr.open(self.type, self.url, self.async);
                if (self.requestHeader.header && self.requestHeader.value) {
                    self.xhr.setRequestHeader(self.requestHeader.header, self.requestHeader.value);
                }
                self.xhr.type = self.xhrType;
                if (self.form) {
                    self.data = self.getFormData();
                }
                else {
                    if (self.type == "POST") {
                        self.data = JSON.stringify(self.data);
                    }
                }
                self.xhr.send(self.data);
            });
        }
    }
    init() {
        if (this.form != null && this.data == null) {
            this.data = this.getFormData();
        }
    }
}


export default XHRWrap;
