const articleFinder = (XHRWrap) => {
    const contentForm = document.querySelector("form[name='content']");
    if (contentForm) {
        let timer;
        // Connected Articles
        const connectionArticlesList = document.getElementById("contents-fields-list")
        contentForm.addEventListener("change", function(e){
            if (e.target.dataset.hasOwnProperty("name") && (e.target.dataset.name == "article-connection" || e.target.dataset.name == "parent-connection")) {
                let connectionItem = e.target.parentElement;
                let findResultList = e.target.nextElementSibling;
                let connectionItemId = connectionItem.parentElement.querySelector("[data-id]");
                if (e.target.value == "" && e.target.dataset.hasOwnProperty("title") && e.target.dataset.title != "") {
                    e.target.value = e.target.dataset.title;
                    connectionItemId.innerText = e.target.dataset.id;
                    clearTimeout(timer);
                    connectionItem.classList.remove("finding");
                    connectionItem.classList.remove("finded");
                    findResultList.innerHTML = "";
                }
            }
        });

        // Connected Articles
        contentForm.addEventListener("input", function(e){
            if (e.target.dataset.hasOwnProperty("name") && (e.target.dataset.name == "article-connection" || e.target.dataset.name == "parent-connection")) {
                let connectionItem = e.target.parentElement;
                let findResultList = e.target.nextElementSibling;
                if (e.target.value.length > 2) {
                    clearTimeout(timer);
                    let findResultSelect = findResultList.nextElementSibling;
                    findResultList.innerHTML = "";
                    connectionItem.classList.add("finding");
                    timer = setTimeout(function(){
                        let findImage = new XHRWrap({
                            url: "/admin/api/v1/content-choices?search=" + e.target.value,
                            type: "GET",
                        });
                        findImage.send().then(
                            result => {
                                const findResult = result.contents;
                                if (!(findResult.length == 0)) {
                                    findResult.innerHTML = "";
                                    let findedText = document.createElement("div");
                                    findedText.classList.add("connection-list__result-item--text");
                                    findedText.innerText = "Znalezione elementy:"
                                    findResultList.appendChild(findedText);

                                    for (var item in findResult) {
                                        if (item == "innerHTML") {
                                            continue;
                                        }
                                        if (findResult.hasOwnProperty(item)) {
                                            let findedItem = document.createElement("div");
                                            findedItem.classList.add("connection-list__result-item");
                                            findedItem.dataset.value = findResult[item];
                                            findedItem.innerText = findResult[item] + ": " + item;
                                            findResultList.appendChild(findedItem);
                                        }
                                    }
                                }
                                else {
                                    findResultList.innerHTML = "<span>Nie znaleziono elementów</span>";
                                }
                                connectionItem.classList.remove("finding");
                                connectionItem.classList.add("finded");
                            },
                            error => {
                                console.log("Error", error);
                            }
                        )
                    }, 1000);
                }
                else {
                    clearTimeout(timer);
                    connectionItem.classList.remove("finding");
                    connectionItem.classList.remove("finded");
                    findResultList.innerHTML = "";
                }
            }
        });

        connectionArticlesList.addEventListener("click", function(e){
            if (e.target.classList.contains("connection-list__result-item") && e.target.dataset.hasOwnProperty("value")) {
                clearTimeout(timer);
                let parent = e.target.closest(".connection-list__connection-item");
                console.log(parent.parentElement);

                let id = parent.parentElement.querySelector(".connection-list__connection-item-id--value");
                id.innerText = "";
                id.innerText = e.target.dataset.value;

                let findResultList = parent.querySelector(".connection-list__result");
                findResultList.innerHTML = "";

                let input = parent.querySelector(".connection-list__input");
                input.value = e.target.innerText;
                input.dataset.title = e.target.innerText;

                let option = document.createElement("option");
                option.innerText = e.target.dataset.value;
                option.value = e.target.dataset.value;
                option.selected = true;

                let select = parent.querySelector(".connection-list__select");
                select.innerHTML = "";
                select.appendChild(option);

                parent.classList.remove("finding");
                parent.classList.remove("finded");
            }
        });

        const connectionParentElement = document.querySelector(".connection-parent__connection-item");
        if (connectionParentElement) {
            connectionParentElement.addEventListener("click", function(e){
                if (e.target.classList.contains("connection-list__result-item") && e.target.dataset.hasOwnProperty("value")) {
                    clearTimeout(timer);

                    let findResultList = this.querySelector(".connection-parent__result");
                    findResultList.innerHTML = "";

                    let id = this.parentElement.querySelector(".connection-list__connection-item-id--value");
                    id.innerText = "";
                    id.innerText = e.target.dataset.value;

                    let input = this.querySelector(".connection-parent__input");
                    input.value = e.target.innerText;
                    input.dataset.title = e.target.innerText;

                    let option = document.createElement("option");
                    option.innerText = e.target.dataset.value;
                    option.value = e.target.dataset.value;
                    option.selected = true;

                    let select = this.querySelector(".connection-parent__select");
                    select.innerHTML = "";
                    select.appendChild(option);

                    this.classList.remove("finding");
                    this.classList.remove("finded");
                }
            });
        }

        const connectionParentClear = document.querySelector(".connection-parent__clear");
        if (connectionParentClear) {
            connectionParentClear.addEventListener("click", function(e){
                let findResultList = document.querySelector(".connection-parent__result");
                findResultList.innerHTML = "";

                let id = document.querySelector(".connection-list__connection-item-id--value");
                id.innerText = "";

                let input = document.querySelector(".connection-parent__input");
                input.value = "";
                input.dataset.title = "";

                let select = document.querySelector(".connection-parent__select");
                select.innerHTML = "";
            });
        }
    }

}
export default articleFinder;
